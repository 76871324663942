import { lazy, Suspense } from "react";

const PLayout = lazy(() => import("../components/PLayout/index"));
const UserCenterLayout = lazy(() => import("../components/UserCenter/index"));

const Login = lazy(() => import("../pages/User/login"));
const Passwd = lazy(() => import("../pages/User/passwd"));
const Register = lazy(() => import("../pages/User/register"));
const EduAccount = lazy(() => import("../pages/User/edu_account"));
const EduEmail = lazy(() => import("../pages/User/edu_email"));

const AlgorithmDetail = lazy(() => import("../pages/Algorithm/index"));
const AlgorithmAtomSegEn = lazy(() => import("../pages/Algorithm/atomseg_en"));

const AlgorithmAtomSeg = lazy(() => import("../pages/Algorithm/atomseg"));
const AlgorithmNatCodon = lazy(() => import("../pages/Algorithm/natcodon"));
const AlgorithmCutedgeOpt = lazy(() => import("../pages/Algorithm/cutedgeopt"));

const MineList = lazy(() => import("../pages/Mine/index"));
const MineCenter = lazy(() => import("../pages/Mine/center"));
const MineOrder = lazy(() => import("../pages/Mine/order"));
const MineCart = lazy(() => import("../pages/Mine/shop_cart"));
const MineInvoice = lazy(() => import("../pages/Mine/invoice"));
const MineResult = lazy(() => import("../pages/Mine/result"));

const PaySuccess = lazy(() => import("../pages/Pay/success"));

const Home = lazy(() => import("../pages/Home/index"));

const lazyLoad = (children) => {
  return <Suspense fallback={<h1>Loading...</h1>}>{children}</Suspense>;
};

export const routers = [
  {
    path: "/",
    element: lazyLoad(<PLayout />),
    children: [
      {
        path: "/",
        element: lazyLoad(<Home />),
      },
      {
        path: "/algorithm/atomseg",
        element: lazyLoad(<AlgorithmAtomSeg />),
      },
      // {
      //   path: "/algorithm/natcodon",
      //   element: lazyLoad(<AlgorithmNatCodon />),
      // },
      {
        path: "/algorithm/cutedgeopt",
        element: lazyLoad(<AlgorithmCutedgeOpt />),
      },
      {
        path: "/edu/verify/:id",
        element: lazyLoad(<EduAccount />),
      },
      {
        path: "/edu/email",
        element: lazyLoad(<EduEmail />),
      },
      {
        path: "/mine/list",
        element: lazyLoad(<MineList />),
      },
      {
        path: "/mine/center",
        element: lazyLoad(<MineCenter />),
      },
      {
        path: "/pay/success",
        element: lazyLoad(<PaySuccess />),
      },
    ],
  },
  {
    path: "/",
    element: lazyLoad(<UserCenterLayout />),
    children: [
      {
        path: "/user/center",
        element: lazyLoad(<MineCenter />),
      },
      {
        path: "/user/cart",
        element: lazyLoad(<MineCart />),
      },
      {
        path: "/user/order",
        element: lazyLoad(<MineOrder />),
      },
      {
        path: "/user/invoice",
        element: lazyLoad(<MineInvoice />),
      },
      {
        path: "/user/result/:order_sn",
        element: lazyLoad(<MineResult />),
      },
    ],
  },
  {
    path: "/algorithm/detail",
    element: lazyLoad(<AlgorithmDetail />),
  },
  {
    path: "/login",
    element: lazyLoad(<Login />),
  },
  {
    path: "/passwd",
    element: lazyLoad(<Passwd />),
  },
  {
    path: "/register",
    element: lazyLoad(<Register />),
  },
  {
    path: "/algorithm/2d4600122a864ab9",
    element: lazyLoad(<AlgorithmAtomSegEn />),
  },
];
